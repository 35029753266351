import { CommonModule } from "@angular/common"
import { NgModule } from "@angular/core"
import { Route } from "@angular/router"

import { NzModule } from "@anzar/core"

import { PyzarCommonModule } from "@pyzar/common.module"

import { OrgApi } from "@backend/org.api"
import { StatsApi } from "@backend/stats.api"

import { ComponentsModule } from "../components"
import { AutoFillComponent } from "./auto-fill.component"
import { BNOComponent } from "./blocks/bno.component"
import { DaytimeShelterComponent } from "./blocks/daytime_shelter.component"
import { DispatcherCapacityComponent } from "./blocks/dispatcher-capacity.component"
import { DispatcherComponent } from "./blocks/dispatcher.component"
import { KenysziMarkedComponent } from "./blocks/kenyszi-marked.component"
import { RoomUsageComponent } from "./blocks/room-usage.component"
import { RsztopComponent } from "./blocks/rsztop.component"
import { StreetCareDutyComponent } from "./blocks/street-care-duty.component"
import { StreetCareSumComponent } from "./blocks/street-care-sum.component"
import { Usage2Component } from "./blocks/usage2.component"
import { UsageComponent } from "./blocks/usage.component"
import { StatsScreen } from "./stats.screen"

export const STATS_ROUTES: Route[] = [{ path: "", component: StatsScreen }]

@NgModule({
    imports: [CommonModule, NzModule, OrgApi, StatsApi, ComponentsModule, PyzarCommonModule],
    declarations: [
        StatsScreen,
        AutoFillComponent,
        DispatcherComponent,
        BNOComponent,
        StreetCareDutyComponent,
        StreetCareSumComponent,
        DispatcherCapacityComponent,
        UsageComponent,
        Usage2Component,
        DaytimeShelterComponent,
        KenysziMarkedComponent,
        RsztopComponent,
        RoomUsageComponent
        // KenysziComponent
    ],
    exports: [
        StatsScreen,
        AutoFillComponent,
        DispatcherComponent,
        BNOComponent,
        StreetCareDutyComponent,
        StreetCareSumComponent,
        DispatcherCapacityComponent,
        UsageComponent,
        Usage2Component,
        KenysziMarkedComponent,
        RsztopComponent
        // KenysziComponent
    ],
    entryComponents: [AutoFillComponent]
})
export class StatsModule {}
