import { AfterViewInit, ChangeDetectorRef, Component, Inject, Input, OnDestroy, ViewChild } from "@angular/core"
import { DomSanitizer, SafeStyle } from "@angular/platform-browser"

import { Observable, Subscriber } from "rxjs"
import { map, shareReplay, startWith } from "rxjs/operators"

import {
    ColumnsComponent,
    DataSourceDirective,
    Destruct,
    DropdownLayer,
    LayerService,
    ListFilterService,
    StaticSource
} from "@anzar/core"

import { Agreement } from "@backend/__anzar_rpc_output"
import { Client } from "@backend/client.api"
import { EntryStatus, KenysziReportEntry, KenysziReportEntryBackend } from "@backend/kenyszi.api"
import { Provider } from "@backend/org.api"

import { ClientService, LeaveProviderComponent } from "../client.module"
import { MarkService } from "../mark.module"

const ENTRY_STATUS_LABELS = EntryStatus.DATA.data.reduce((dict: { [key: string]: string }, el) => {
    dict[el.id] = el.label
    return dict
}, {})

@Component({
    selector: ".rege-kenyszi-grid",
    templateUrl: "./grid.component.pug",
    providers: [ListFilterService, MarkService]
})
export class KenysziGridComponent implements AfterViewInit, OnDestroy {
    public readonly destruct = new Destruct()

    @ViewChild("columns", { static: true }) public readonly columns: ColumnsComponent
    @Input() public readonly providerSrc: StaticSource<Provider>
    @Input() public displayProvider: boolean

    public rowsTemplate: SafeStyle
    public gridTemplate: SafeStyle
    public readonly statusLabels = ENTRY_STATUS_LABELS
    private markCache: { [key: string]: Observable<boolean> } = {}

    public constructor(
        @Inject(DataSourceDirective) public readonly source: DataSourceDirective,
        @Inject(ClientService) private readonly clientSvc: ClientService,
        @Inject(DomSanitizer) private readonly sanitizer: DomSanitizer,
        @Inject(ChangeDetectorRef) private readonly cdr: ChangeDetectorRef,
        @Inject(KenysziReportEntryBackend) private readonly entryBackend: KenysziReportEntryBackend,
        @Inject(MarkService) private readonly markSvc: MarkService,
        @Inject(LayerService) private readonly layer: LayerService
    ) {}

    public ngAfterViewInit() {
        this.destruct
            .subscription(this.columns.layoutChanged)
            .pipe(startWith(null))
            .subscribe(l => {
                this.gridTemplate = this.sanitizer.bypassSecurityTrustStyle(`52px / ${this.columns.gridColTemplate}`)
                this.cdr.markForCheck()
            })

        this.destruct.subscription(this.source.storage.items).subscribe(c => {
            this.rowsTemplate = this.sanitizer.bypassSecurityTrustStyle(
                `repeat(${this.source.storage.lastIndex}, 52px) / 1fr`
            )
            this.cdr.markForCheck()
        })
    }

    public showClientSheet(event: Event, client: Client) {
        event.preventDefault()
        this.clientSvc.showSheet(client.id).subscribe()
    }

    public showClientSheetData(client: Client) {
        this.clientSvc.showSheet(client.id, ClientService.TAB_BASIC_DATA).subscribe()
    }

    public toggleAction(item: KenysziReportEntry) {
        const action = item.action === "add" ? "remove" : "add"
        this.entryBackend.save({ data: { id: item.id, action: action } }).subscribe(entry => {
            item.action = entry.action
            item.status = entry.status
            item.error = entry.error
            this.cdr.markForCheck()
        })
    }

    public updateProvider(item: KenysziReportEntry, provider_id: number) {
        if (item.provider_id !== provider_id) {
            item.provider_id = provider_id
            item.status = "pending"
            this.entryBackend
                .save({ data: { id: item.id, provider_id: item.provider_id, status: "pending" } })
                .subscribe()
        }
    }

    public hasSheetError(clientId: number) {
        if (this.markCache[clientId]) {
            return this.markCache[clientId]
        } else {
            return (this.markCache[clientId] = this.destruct
                .subscription(this.markSvc.getClientAlertLevel(clientId, ["required_fields"]))
                .pipe(
                    map(alerts => {
                        if (alerts && alerts.length) {
                            for (const alert of alerts) {
                                if (alert.icon === "mdi/clipboard-alert-outline") {
                                    return true
                                }
                            }
                        }
                        return false
                    }),
                    shareReplay(1)
                ))
        }
    }

    public showAgreement(event: Event, client: Client, agreement: Agreement) {
        this._showAgreement(event, client, agreement).subscribe(() => this.source.reload())
    }

    public _showAgreement(event: Event, client: Client, agreement: Agreement) {
        return new Observable((dest: Subscriber<boolean>) => {
            const behavior = new DropdownLayer({
                backdrop: { hideOnClick: true, type: "empty" },
                position: {
                    align: "top center",
                    anchor: {
                        align: "bottom center",
                        margin: "8 0",
                        ref: event.target as HTMLElement
                    }
                },
                elevation: 10,
                rounded: 3
            })
            const ref = this.layer.createFromComponent(LeaveProviderComponent, behavior, null, [
                { provide: Client, useValue: client },
                { provide: Agreement, useValue: agreement }
            ])
            ref.show()
            ref.subscribe(event => {
                if (event.type === "hiding") {
                    dest.complete()
                } else if (event.type === "save") {
                    dest.next(true)
                }
            })
            return () => ref.hide()
        })
    }

    public ngOnDestroy() {
        this.destruct.run()
    }
}
