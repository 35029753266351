import { Component, Inject } from "@angular/core"
import { FormControl, FormGroup, Validators } from "@angular/forms"

import { endOfMonth, startOfMonth, subDays, subMonths } from "date-fns"

import { Destructible } from "@anzar/core"

import { StatsService } from "../stats.service"

@Component({
    selector: ".rege-stats-usage2",
    templateUrl: "./usage2.component.pug"
})
export class Usage2Component extends Destructible {
    public readonly form = new FormGroup({
        date_from: new FormControl(startOfMonth(new Date()), [Validators.required]),
        date_to: new FormControl(endOfMonth(new Date()), [Validators.required]),
        comparsion_from: new FormControl(subMonths(startOfMonth(new Date()), 1), [Validators.required]),
        comparsion_to: new FormControl(subMonths(endOfMonth(new Date()), 1), [Validators.required])
    })

    public constructor(@Inject(StatsService) private readonly stats: StatsService) {
        super()

        this.destruct.subscription(this.form.get("date_from").valueChanges).subscribe(value => {
            this.form.get("comparsion_to").setValue(subDays(value, 1))
        })
    }

    public download() {
        const data = this.form.value
        this.stats.usage2(data.date_from, data.date_to, data.comparsion_from, data.comparsion_to)
    }
}
