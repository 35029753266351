import { Component, Inject, OnDestroy, ChangeDetectorRef, ChangeDetectionStrategy } from "@angular/core"
import { ActivatedRoute } from "@angular/router"
import { Location } from "@angular/common"
import { switchMap, shareReplay, debounceTime, take, mapTo, finalize } from "rxjs/operators"
import { of, Subject, merge, zip } from "rxjs"
import { parse, format, isSameDay } from "date-fns"

import { Destruct, LayerService, ModalLayer, DataSourceDirective, LoadFields, StaticSource } from "@anzar/core"
import { TaskService } from "@pyzar/task.module"
import { KenysziReportBackend, KenysziReportEntryBackendSource, KenysziReportEntry } from "@backend/kenyszi.api"
import { Client } from "@backend/client.api"
import { ProviderBackend, Provider } from "@backend/org.api"
import { KenysziStatPopupComponent, PROVIDER_IDS, REPORT_DATE } from "./stat-popup.component"


const REPONT_ENTRY_FIELDS: LoadFields<KenysziReportEntry> = [
    "id", "client_id", "error", "action", "status", "provider_id", "manually_added",
    { client: ["id", "name", "birth_date", "social_insurance_id"] },
    { agreement: ["id", "begin", "end", "idTevadminIgenyles", "leave_reason"] }
]


interface Summary {
    total: number
    totalBreakdown?: string
    reported: { total: number, breakdown?: string }
    pending: { total: number, breakdown?: string }
}


@Component({
    selector: ".rege-kenyszi-report-screen",
    host: {
        "[style.height]": "'100%'",
        "[style.width]": "'100%'",
        "[style.position]": "'relative'",
    },
    templateUrl: "./report.screen.pug",
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [DataSourceDirective]
})
export class ReportScreen implements OnDestroy {
    public readonly destruct = new Destruct()

    public set providerIds(val: number[]) {
        if (this._providerIds !== val) {
            this._providerIds = val
            this._providerIdsChange.next(val)
        }
    }
    public get providerIds(): number[] { return this._providerIds }
    private _providerIds: number[]
    private _providerIdsChange = new Subject<number[]>()
    private _providerIds$ = this._providerIdsChange.pipe(shareReplay(1))

    public set date(val: Date) {
        if (!isSameDay(this._date, val)) {
            this._date = val
            this._dateChange.next(val)
        }
    }
    public get date(): Date { return this._date }
    private _date: Date
    private _dateChange = new Subject<Date>()
    private _date$ = this._dateChange.pipe(shareReplay(1))

    public set reportReady(val: boolean) {
        if (this._reportReady !== val) {
            this._reportReady = val
            this.cdr.detectChanges()
        }
    }
    public get reportReady(): boolean { return this._reportReady }
    private _reportReady: boolean = false

    public readonly providerSrc = new StaticSource(Provider, [])
    public readonly reportId: number
    public summary: Summary
    public providerTitle: string
    public sectionId: number

    public constructor(
        @Inject(ActivatedRoute) private readonly route: ActivatedRoute,
        @Inject(DataSourceDirective) public readonly source: DataSourceDirective<KenysziReportEntry>,
        @Inject(KenysziReportBackend) private readonly reportBackend: KenysziReportBackend,
        @Inject(ProviderBackend) private readonly providerBackend: ProviderBackend,
        @Inject(KenysziReportEntryBackendSource) reportSrc: KenysziReportEntryBackendSource,
        @Inject(ChangeDetectorRef) private readonly cdr: ChangeDetectorRef,
        @Inject(TaskService) private readonly taskSvc: TaskService,
        @Inject(LayerService) private readonly layerSvc: LayerService,
        @Inject(Location) private readonly location: Location) {
        source.dataSource = reportSrc
        source.sort = { "client.name.formatted": "asc" } as any
        source.loadFields(REPONT_ENTRY_FIELDS)

        this.destruct.subscription(this._providerIds$)
            .pipe(
                switchMap(providerIds => {
                    return this._updateProvidersSrc(providerIds).pipe(mapTo(providerIds))
                }),
                switchMap(providerIds => {
                    return this.providerBackend.search(
                        { filter: { id: { in: providerIds } } },
                        { loadFields: ["title", "parent_id"] })
                }),
            )
            .subscribe(providers => {
                this.providerTitle = providers.map(p => p.title).join(", ")
                this.sectionId = providers[0].parent_id
                cdr.markForCheck()
            })

        this.destruct.subscription(merge(this._date$, this._providerIds$)).pipe(
            // tap(val => this.reportReady = false),
            debounceTime(10),
            switchMap(val => zip(this._date$, this._providerIds$)),
            switchMap(val => {
                const [date, providerIds] = val
                this.location.replaceState(`/kenyszi/jelentes/${providerIds.join(",")}/${format(date, "yyyy-MM-dd")}`)
                return this.reportBackend.update_report({ provider_ids: providerIds, date: date })
            })
        ).subscribe(reportId => {
            (this as any).reportId = reportId
            source.filter = { ...source.filter, report_id: reportId ? reportId : -1 }
            this.reportReady = true
        })

        this.destruct.subscription(route.params).subscribe(params => {
            this.providerIds = params["providerIds"].split(",").map(Number)
            this.date = parse(params["date"], "yyyy-MM-dd", new Date())

        })

        this.destruct.subscription(source.storage.items).subscribe(v => {
            this._updateSummary(source.storage.data)
            this.cdr.markForCheck()
        })
    }

    public onDateChange(date: Date) {
        this.date = date
    }

    public addClient(client: Client) {
        this.reportBackend
            .add_client({
                report_id: this.reportId,
                client_id: client.id,
                date: this.date,
                provider_id: this.providerIds[0]
            })
            .subscribe(result => {
                this.source.reload()
            })
    }

    public sendReport() {
        this.taskSvc
            .start("kenyszi-report", { report_id: this.reportId })
            .pipe(finalize(() => this.source.reload()))
            .subscribe()

        // this.reportBackend
        //     .send_report({ report_id: this.reportId })
        //     .pipe(
        //         this.taskSvc.handleTaskStart({
        //             align: "bottom center",
        //             beginMsg: "Jelentés előkészítése",
        //             onError: () => {
        //                 this.source.reload()
        //             }
        //         }),
        //         finalize(() => {
        //             this.source.reload()
        //         })
        //     )
        //     .subscribe()
    }

    public downloadStat() {
        const behavior = new ModalLayer({
            trapFocus: true,
            elevation: 10,
            rounded: 3
        })
        const ref = this.layerSvc.createFromComponent(KenysziStatPopupComponent, behavior, null, [
            { provide: PROVIDER_IDS, useValue: this.providerIds },
            { provide: REPORT_DATE, useValue: this.date },
        ])
        ref.show()
    }

    public ngOnDestroy() {
        this.destruct.run()
    }

    private _updateProvidersSrc(providerIds: number[]) {
        return this.providerBackend.search({
            filter: { id: { in: providerIds } },
            order: {},
            begin: 0,
            count: providerIds.length
        }).pipe(
            switchMap(providers => {
                this.providerSrc.replace(providers)
                return of(providers)
            }),
            take(1)
        )
    }

    private _updateSummary(items: { [key: number]: KenysziReportEntry }) {
        let summary: Summary = {
            total: 0,
            pending: { total: 0 },
            reported: { total: 0 },
        }
        let breakdown: { [key: string]: { total: number, pending: number, reported: number } } = {}

        for (const k in items) {
            const item = items[k]
            let bd = breakdown[item.provider_id]
                || (breakdown[item.provider_id] = { total: 0, pending: 0, reported: 0 })
            summary.total++
            bd.total++

            if (item.status === "added") {
                summary.reported.total++
                bd.reported++
            } else {
                summary.pending.total++
                bd.pending++
            }
        }

        const bdKeys = Object.keys(breakdown)
        if (bdKeys.length > 1) {
            let bdTotal: string[] = []
            let bdPending: string[] = []
            let bdReported: string[] = []
            for (const k of bdKeys) {
                const st = this.providerSrc.getSync(k)
                const title = st ? st.title : "---"
                bdTotal.push(`${breakdown[k].total} ${title}`)
                bdPending.push(`${breakdown[k].pending} ${title}`)
                bdReported.push(`${breakdown[k].reported} ${title}`)
            }
            summary.totalBreakdown = bdTotal.join(" + ")
            summary.pending.breakdown = bdPending.join(" + ")
            summary.reported.breakdown = bdReported.join(" + ")
        }

        this.summary = summary
        // this.cdr.detectChanges()
    }
}
