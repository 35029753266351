import { Component, Inject, Input } from "@angular/core"
import { FormGroup, FormControl, Validators } from "@angular/forms"
import { startOfMonth, endOfMonth } from "date-fns"

import { StatsService, DispatcherStatType } from "../stats.service"


@Component({
    selector: ".rege-stats-scd",
    templateUrl: "./street-care-duty.component.pug"
})
export class StreetCareDutyComponent {
    public readonly form = new FormGroup({
        date_from: new FormControl(startOfMonth(new Date()), [Validators.required]),
        date_to: new FormControl(endOfMonth(new Date()), [Validators.required])
    })

    public constructor(
        @Inject(StatsService) private readonly stats: StatsService) {
    }

    public download() {
        this.stats.street_care_duty(this.form.value.date_from, this.form.value.date_to)
    }
}
